import {
  CurrencyDollarIcon,
  TemplateIcon,
  ChartBarIcon,
  ClockIcon,
  FilterIcon,
  LightningBoltIcon,
  ChatIcon,
  CashIcon
} from '@heroicons/react/outline';
import Hover from 'react-3d-hover';

const Features = (): JSX.Element => {
  const features = [
    {
      name: 'Generate extra revenue with ease',
      description:
        'On average, abandoned checkout emails convert at a rate of 15%. Recover allows you to capitalise on potentially lost revenue.',
      active: true,
      icon: CurrencyDollarIcon
    },
    {
      name: 'Use our prebuilt email template',
      description:
        'Utilize our prebuilt, optimized email template to get sending in no time. No worrying about compatibility across devices. Simply add your logo, brand colour and custom copy.',
      active: true,
      icon: TemplateIcon
    },
    {
      name: 'Access abandoned checkout analytics',
      description:
        'Use our analytics dashboard to monitor your current abandoned checkout email conversion rate.',
      active: true,
      icon: ChartBarIcon
    },
    {
      name: 'Choose a send time',
      description:
        'You can decide whether to send your email within 30 mins, 1 hour (recommended) or 3 hours.',
      active: true,
      icon: ClockIcon
    },
    {
      name: 'Create custom email flows',
      description:
        'Build your own abandoned checkout email flow with advanced filtering options.',
      active: false,
      icon: FilterIcon
    },
    {
      name: 'Connect to Zapier',
      description:
        "Get notified of successfully Recover'd emails and perform an action of your choice with Zapier.",
      active: false,
      icon: LightningBoltIcon
    },
    {
      name: 'Send Recover messages via iMessage',
      description: 'Build your own SMS template and send texts via iMessage.',
      active: false,
      icon: ChatIcon
    },
    {
      name: 'Unique discount codes',
      description: 'Add unique discount codes to your Recover email.',
      active: false,
      icon: CashIcon
    }
  ];

  return (
    <div
      id="features"
      className="pt-14 sm:pt-32 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-primary-2 to-primary"
    >
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-extrabold text-white tracking-tight sm:text-5xl mb-3 text-center underline">
          Why use Recover?
        </h2>
        <dl className="mt-12 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-2 lg:gap-x-8">
          {features.map((feature) => (
            <Hover perspective={500} speed={600} key={feature.name}>
              <dt>
                {feature.icon && <feature.icon className="w-12 h-auto mb-3" />}
                <p className="text-2xl font-bold tracking-tight">
                  {feature.name}
                </p>
                {feature.active === false && (
                  <span className="mt-2 px-3 py-1 text-xs font-semibold uppercase bg-gray-300 rounded-full inline-block">
                    Coming Soon
                  </span>
                )}
              </dt>
              <dd className="mt-2 text-xl">{feature.description}</dd>
            </Hover>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default Features;
