import Link from 'next/link';
import { differenceInDays } from 'date-fns';
import { useUser } from '@/utils/user/client/useUser';
// import Testimonials from '@/components/Testimonials';
import Features from '@/components/Features';
import HowItWorks from '@/components/HowItWorks';
import { SEOMeta } from '@/components/SEOMeta';

const Index = (): JSX.Element => {
  const { userLoaded } = useUser();

  return (
    <>
      <SEOMeta />
      <div>
        <div className="lg:relative bg-gradient-to-b from-primary to-primary-2">
          <div className="wrapper relative z-10 pt-10 lg:pt-14 text-center">
            <div className="max-w-5xl mx-auto relative">
              <h1 className="text-3xl sm:text-4xl tracking-tight font-extrabold text-white lg:text-5xl relative z-10">
                <span className="block xl:inline">
                  Recover abandoned Stripe checkouts.
                </span>
                <span className="block text-white xl:block">
                  Earn <span className="underline">extra revenue</span> with
                  ease.
                </span>
              </h1>
              <p className="mt-4 font-light text-white text-lg sm:text-xl relative z-10">
                Easily convert your abandoned Stripe checkouts and earn extra
                revenue with <span className="font-bold">Recover</span>. No
                upfront fees. You only pay when we successfully{' '}
                <span className="font-bold">Recover</span> an abandoned
                checkout.
              </p>
              <div className="mt-12">
                <div>
                  <a
                    href={userLoaded ? '/dashboard' : '/signup'}
                    className="px-8 py-3 inline-block border border-transparent font-semibold rounded-md bg-white text-primary transition-all md:py-4 md:text-xl md:px-16"
                  >
                    {userLoaded ? 'View Dashboard' : 'Get Started for FREE'}
                  </a>
                  <Link
                    href="https://undock.com/rosinghal/t/recover"
                    rel="noreferrer"
                  >
                    <a
                      target="_blank"
                      className="mx-2 px-8 py-3 inline-block border border-transparent font-semibold rounded-md bg-white text-primary transition-all md:py-4 md:text-xl md:px-16"
                    >
                      Book a demo
                    </a>
                  </Link>
                </div>
                {differenceInDays(new Date(), new Date('2022-04-29')) < 2 && (
                  <div className="mt-3">
                    <Link
                      href="https://www.producthunt.com/posts/recover-so-public-p-l-page?utm_source=badge-featured&utm_medium=badge"
                      rel="noreferrer"
                    >
                      <a target="_blank">
                        <img
                          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=344567&theme=light"
                          className="mx-auto"
                          alt="Product Hunt"
                        />
                      </a>
                    </Link>
                  </div>
                )}
                <a
                  className="underline block mt-3 text-white text-sm"
                  href="#how-it-works"
                >
                  How does it work?
                </a>
              </div>
              {!userLoaded && (
                <p className="text-xs text-white mt-4 block">
                  No credit card required. You only pay when we successfully
                  convert an abandoned checkout.
                </p>
              )}
              <div className="rounded-lg relative mt-20">
                <video
                  className="w-full h-auto shadow-xl rounded-xl border-l-8 border-8 border-white"
                  loop
                  muted
                  autoPlay
                  playsInline
                  preload="meta"
                  onError={(): void => {
                    console.log('error');
                  }}
                >
                  <source src={`/recover-video.mp4`} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>

        <Features />

        <HowItWorks />

        {/* <Testimonials /> */}
      </div>
    </>
  );
};

export default Index;
