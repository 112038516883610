const DownArrow = ({ ...props }: JsonB): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 394.873 394.873"
      version="1.1"
      viewBox="0 0 394.873 394.873"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M334.678 145.951c-19.584-38.556-59.364-58.752-99.756-67.32C134.553 57.211 43.365 121.471.525 208.375c-2.448 4.896 4.284 8.567 7.344 4.283C57.441 134.323 132.717 75.571 230.638 92.095c91.188 15.912 111.996 94.86 112.608 175.643 0 8.568 13.464 8.568 13.464 0 .612-41.615-3.061-84.455-22.032-121.787z"></path>
      <path d="M220.846 114.739c-44.676-5.508-88.74 13.464-126.685 35.496-22.032 12.852-67.32 41.616-64.872 72.215 0 2.448 3.06 3.061 4.896 1.225 39.78-56.304 107.1-98.532 178.705-96.696 79.561 2.448 89.353 76.5 89.353 140.148 0 9.18 14.075 9.18 14.075 0-.001-70.992-12.852-141.984-95.472-152.388z"></path>
      <path d="M380.578 238.975c-6.12 13.464-25.704 69.156-47.124 66.096-9.792-1.224-19.584-10.403-26.929-16.523-12.852-9.792-25.703-20.196-39.168-29.376-4.283-3.061-9.18 3.672-5.508 7.344 18.972 17.136 46.512 52.021 74.664 53.856 29.376 1.224 48.348-54.469 57.528-73.44 4.285-9.181-9.18-16.526-13.463-7.957z"></path>
    </svg>
  );
};

export default DownArrow;
