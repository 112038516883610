import DownArrow from '@/components/icons/DownArrow';
import { useUser } from '@/utils/user/client/useUser';

const HowItWorks = (): JSX.Element => {
  const { userLoaded } = useUser();

  const features: {
    name: string;
    video: string;
    description?: string;
  }[] = [
    {
      name: 'Step 1: Connect your Stripe Account',
      video: '/add-account.mp4'
    },
    {
      name: 'Step 2: Set your send schedule',
      video: '/send-schedule.mp4'
    },
    {
      name: 'Step 3: Edit the content/add your logo',
      video: '/edit-content.mp4'
    },
    {
      name: 'Step 4: Set your email live and earn extra revenue',
      video: '/enable-email.mp4'
    }
  ];

  return (
    <div
      id="how-it-works"
      className="pt-14 sm:pt-32 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-primary to-primary-2"
    >
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-extrabold text-white tracking-tight sm:text-5xl mb-3 text-center underline">
          How It Works
        </h2>
        <dl className="mt-12 space-y-4 text-center">
          {features.map((feature, index) => (
            <div key={feature.name}>
              <div>
                <dt>
                  <p className="text-2xl font-bold tracking-tight text-white">
                    {feature.name}
                  </p>
                </dt>
                {feature.description && (
                  <dd className="mt-2 text-xl text-white">
                    {feature.description}
                  </dd>
                )}
              </div>
              <div>
                <video
                  className="max-w-2xl w-full h-auto rounded-xl mx-auto mt-5 border-4 border-primary shadow-lg"
                  loop
                  muted
                  autoPlay
                  playsInline
                  preload="meta"
                  onError={(): void => {
                    console.error('error');
                  }}
                >
                  <source src={feature.video} type="video/mp4" />
                </video>
              </div>
              {features?.length !== index + 1 && (
                <DownArrow
                  className="w-28 h-auto mx-auto"
                  style={{ fill: '#fff' }}
                />
              )}
            </div>
          ))}
        </dl>
        <div className="text-center mt-12">
          <a
            href={userLoaded ? '/dashboard' : '/signup'}
            className="px-8 py-3 inline-block border border-transparent font-semibold rounded-md bg-white transition-all md:py-4 md:text-lg md:px-16"
          >
            {userLoaded ? 'View Dashboard' : 'Get Started for FREE'}
          </a>
          {!userLoaded && (
            <p className="text-xs mt-4 block text-white">
              No credit card required. You only pay when we successfully convert
              an abandoned checkout.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
