import Head from 'next/head';

export const SEOMeta = ({
  title = 'Convert your abandoned Stripe checkouts with Recover',
  description = 'Easily convert your abandoned Stripe checkouts with Recover. No upfront fees. You only pay when we successfully Recover your abandoned checkout on your behalf.',
  keywords = 'Recover, Recover.so, abandoned stripe checkout email, stripe checkout email, stripe checkout recovery',
  img = '/og.png'
}: {
  title?: string;
  description?: string;
  keywords?: string;
  img?: string;
}): JSX.Element => {
  const setTitle = title + ' | Recover.so';

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@recover_so" />
      <meta
        name="twitter:title"
        content="Recover abandoned Stripe checkouts. Earn extra revenue with ease 🚀"
      />
      <meta
        name="twitter:description"
        content="Easily convert your abandoned Stripe checkouts with Recover. No upfront fees. You only pay when we successfully Recover an abandoned checkout."
      />
      <meta name="twitter:image" content={`https://recover.so${img}`} />

      {/* Open Graph */}
      <meta property="og:url" content="https://recover.so" key="ogurl" />
      <meta property="og:image" content={img} key="ogimage" />
      <meta property="og:site_name" content="Recover.so" key="ogsitename" />
      <meta property="og:title" content={setTitle} key="ogtitle" />
      <meta property="og:description" content={description} key="ogdesc" />
      <title>{setTitle}</title>
      <link rel="manifest" href="/site.webmanifest" />
      <link
        href="/favicon-16x16.png"
        rel="icon"
        type="image/png"
        sizes="16x16"
      />
      <link
        href="/favicon-32x32.png"
        rel="icon"
        type="image/png"
        sizes="32x32"
      />
      <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png"></link>
      <meta name="theme-color" content="#705DE4" />
    </Head>
  );
};
